import React, { useState, useEffect } from "react";
import { useWindowWidth } from "../../breakpoints";
// import { Carousel } from "../../components/msp/Carousel";
import { Cta } from "../../components/msp/Cta";
import { StateDefaultWrapper } from "../../components/msp/StateDefaultWrapper";
import { Header1 } from "../../icons/Header1";
import "./style.css"
import { Header } from "../../components/shared/header";
import { Footer } from "../../components/shared/footer";
import { LeadForm } from "../../components/shared/LeadForm";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export const Captives = () => {
  const screenWidth = useWindowWidth();
  const [openModal, setOpenModal] = useState(false);
  const [factor, setFactor] = useState();

  useEffect(() => {
    if (window.innerWidth > 450) {
      setFactor(window.innerWidth / 1440);
    } else {
      setFactor(window.innerWidth / 390);
    }
    document.querySelector(".div-2").style.transform = `scaleX(${factor})`;
    document.querySelector("#app").style.transform = `scaleY(${factor})`;
    document.querySelector(".div-2").style.transformOrigin = `top left`;
    document.querySelector("#app").style.transformOrigin = `top left`;
  });

  useEffect(() => {
    if (openModal) {
      document.querySelector("body").style.overflow = "hidden";
    }
    else {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [openModal])

  return (
    <>
      <div className="captives" >
        <div
          className="div-2"
          style={{
            height: screenWidth < 450 ? "5000px" : screenWidth >= 450 ? "2780px" : undefined,
            width: screenWidth < 450 ? "390px" : screenWidth >= 450 ? "1440px" : undefined,
          }}
        >
           {openModal && (
            <LeadForm
              openModal={openModal}
              setOpenModal={setOpenModal}
              factor={factor}
            />
          )}
          <Header nobg msp blacklogo/>
          <Header1 className="header mobile-only" blacklogo/>

          <div
            className="overlap"
            style={{
              height: screenWidth >= 450 ? "1455px" : screenWidth < 450 ? "2177px" : undefined,
              top: screenWidth >= 450 ? "1px" : screenWidth < 450 ? "60px" : undefined,
              width: screenWidth >= 450 ? "1441px" : screenWidth < 450 ? "390px" : undefined,
            }}
          >
            <div className="desktop-only">
              <div className="rectangle-2" />
              {/* <img className="element" alt="Element" src="/msp/captive.jpeg" /> */}
              <div className="frame-11">
                <div className="component">
                  <p className="rely-on">
                    <span className="span">Unlock Possibilities</span>
                    <span className="text-wrapper-12"> Capture Success </span>
                    <span className="span"> Captive as a Service.</span>
                  </p>
                </div>
                <div className="frame-12">
                  <p className="text-wrapper-13">
                  With expertise in risk management and regulatory compliance, we offer a hassle-free solution, enabling organizations to enhance their insurance strategies while reducing expenses.
                  </p>
                  <Cta
                    arrowForward="/msp/arrow-forward-96.svg"
                    arrowForwardClassName="CTA-2"
                    className="CTA-instance"
                    divClassName="instance-node"
                    property1="default"
                    text="Connect with us"
                    link="/contact-us"
                  />
                </div>
              </div>
              <div className="rectangle-3" />
              <img className="rectangle-4" alt="Rectangle" src="/msp/rectangle-137-1.svg" />
              <img className="rectangle-5" alt="Rectangle" src="/msp/rectangle-138-1.svg" />
              <div className="frame-13">
                <div className="frame-14">
                  <div className="text-wrapper-14">Services we offer  </div>
                  <p className="managing-a-company-s">
                  
                  </p>

                  <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-15">Workforce  </div> 
                          </div>
                </div>
                <div className="text-wrapper-15 text-noraml" >Cover all aspects of talent attraction, nurture and retention. By implementing a comprehensive approach to talent attraction, nurture, and retention, organizations can effectively attract top talent, develop their skills and potential .  </div>


                <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-15">Workplace   </div> 
                          </div>
                </div>
                <div className="text-wrapper-15 text-noraml">By identifying, getting all clearances, executing agreements till facility go live, organizations can successfully establish a new workplace location and ensure its readiness for operations, enabling employees to work efficiently and effectively in a conducive environment.    </div>


                <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-15">Workload   </div> 
                          </div>
                </div>
                <div className="text-wrapper-15 text-noraml">Combining functional and technical expertise with a strategic approach to innovation, organizations can successfully leverage technology to transform their operations, drive growth, and maintain a competitive edge in today's rapidly evolving business landscape.  </div>

                <div className="text-wrapper-14">Approach to deliver   </div>
                
                <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-15">Discover    </div> 
                          </div>
                </div>
                <div className="text-wrapper-15 text-noraml">Understand the business drivers and building a robust business case, organizations can secure buy-in and funding for strategic initiatives that drive growth, innovation, and competitive advantage. </div>


                <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-15">Design     </div> 
                          </div>
                </div>
                <div className="text-wrapper-15 text-noraml">Designing and customizing blueprint for GCC/ODC setup can establish a strategic offshore presence that enhances operational efficiency, accelerates innovation, and drives sustainable growth.  </div>



                <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-15">Execute      </div> 
                          </div>
                </div>
                <div className="text-wrapper-15 text-noraml">Initiate GCC/ODC operations, project execution with confidence, delivering value to clients and stakeholders while building a strong foundation for long-term success.   </div>


                <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-15">Scale       </div> 
                          </div>
                </div>
                <div className="text-wrapper-15 text-noraml"> Increase GCC/ODC size by scaling out operations to accommodate growth, enhance capabilities, and capitalize on emerging opportunities in the global marketplace. </div>

                </div>
              
                {/* <div className="div-wrapper">
                  <div className="frame-15">
                    <div className="frame-16">
                      <div className="frame-17">
                        <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-15">Payroll Management</div>
                          </div>
                        </div>
                        <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="text-wrapper-16">Workforce Management</div>
                        </div>
                        <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="text-wrapper-16">Human Resources</div>
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className="div-3">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="text-wrapper-17">Compliance &amp; Contract Management</div>
                        </div>
                        <div className="div-3">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="text-wrapper-17">Vendor Management</div>
                        </div>
                        <div className="div-3">
                          <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                          <div className="procurement-and">Procurement And Sourcing</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <img className="rectangle-5-advisory" alt="Rectangle" src="/msp/Advisory-transformed.png" /> */}
              </div> 
              {/* <div className="group-2">
                <div className="frame-20">
                  <div className="text-wrapper-18">Project Team</div>
                </div>
                <div className="frame-21">
                  <div className="frame-22">
                    <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                    <div className="frame-19">
                      <div className="text-wrapper-19">Demand management</div>
                    </div>
                  </div>
                  <div className="frame-18">
                    <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                    <div className="text-wrapper-20">Stakeholder management</div>
                  </div>
                  <div className="frame-23">
                    <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                    <div className="text-wrapper-21">Systems management</div>
                  </div>
                  <div className="frame-23">
                    <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                    <div className="text-wrapper-21">Profiles sanity &amp; checks</div>
                  </div>
                  <div className="frame-23">
                    <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                    <div className="text-wrapper-21">Interview coordination</div>
                  </div>
                  <div className="frame-23">
                    <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                    <div className="text-wrapper-21">Continuous Curation of Database</div>
                  </div>
                </div>
              </div> */}
              {/* <div className="text-wrapper-22">Our Structure</div> */}
              {/* <div className="group-3">
                <div className="overlap-2">
                  <img className="group-4" alt="Group" src="/msp/group-34-1.png" />
                  <div className="rectangle-6" />
                  <div className="rectangle-7" />
                  <div className="rectangle-8" />
                  <div className="text-wrapper-23">Requisition PMO</div>
                  <div className="text-wrapper-24">Operation PMO</div>
                  <div className="text-wrapper-25">Vender Management</div>
                </div>
                <div className="group-wrapper">
                  <div className="group-5">
                    <div className="overlap-3">
                      <div className="text-wrapper-26">Management</div>
                    </div>
                    <div className="overlap-4">
                      <div className="overlap-5">
                        <div className="rectangle-9" />
                        <img className="line" alt="Line" src="/msp/line-1-1.svg" />
                        <img className="ellipse-4" alt="Ellipse" src="/msp/ellipse-10-1.png" />
                        <div className="text-wrapper-27">Transition manager</div>
                        <img className="ellipse-5" alt="Ellipse" src="/msp/ellipse-8-1.png" />
                        <img className="group-6" alt="Group" src="/msp/group-31-1.png" />
                      </div>
                      <img className="group-7" alt="Group" src="/msp/group-32-1.png" />
                    </div>
                    <div className="overlap-6">
                      <div className="text-wrapper-28">Transition team</div>
                    </div>
                    <div className="overlap-group-3">
                      <div className="text-wrapper-29">PMO</div>
                    </div>
                    <div className="overlap-7">
                      <div className="rectangle-10" />
                      <div className="text-wrapper-30">Program Manager</div>
                    </div>
                    <img className="group-8" alt="Group" src="/msp/group-33-1.png" />
                  </div>
                </div>
              </div> */}
              {/* <div className="frame-24">
                <div className="text-wrapper-31">Transition Team</div>
                <div className="frame-25">
                  <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                  <div className="frame-19">
                    <div className="text-wrapper-19">Know Your Client</div>
                  </div>
                </div>
                <div className="frame-26">
                  <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                  <div className="text-wrapper-20">Tools &amp; Techniques</div>
                </div>
                <div className="frame-27">
                  <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                  <div className="text-wrapper-21">Processes &amp; Methodologies</div>
                </div>
                <div className="frame-28">
                  <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                  <div className="text-wrapper-21">Reports &amp; Dashboards</div>
                </div>
                <div className="frame-29">
                  <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                  <div className="text-wrapper-21">Stabilization measures</div>
                </div>
                <div className="frame-30">
                  <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                  <div className="text-wrapper-21">Tech Integrations (if applicable)</div>
                </div>
              </div> */}
            </div>

            <div className="mobile-only">
              <div className="frame-33">
                <div className="frame-34" />
              </div>
              <div className="frame-35">
                <p className="rely-on-2">
                  <span className="span">Rely on JoulestoWatts </span>
                  <span className="text-wrapper-12">Master Service Provider</span>
                  <span className="span"> for smooth operations.</span>
                </p>
                <div className="frame-36">
                  <p className="text-wrapper-34">
                    JoulestoWatts has a perfect combination of expertise, technology, and thought leaders to manage your
                    operations in various verticals.
                  </p>
                  <Cta
                    arrowForward="/msp/arrow-forward-95.svg"
                    arrowForwardClassName="CTA-2"
                    className="CTA-instance"
                    divClassName="instance-node"
                    property1="default"
                    text="Connect with us"
                    link="/contact-us"
                  />
                </div>
                <div className="frame-37" />
                <img className="element-2" alt="Element" src="/msp/37.png" />
              </div>
              <div className="overlap-wrapper">
                <div className="overlap-8">
                  <div className="overlap-9">
                    <img className="rectangle-11" alt="Rectangle" src="/msp/rectangle-137.svg" />
                    <div className="frame-38">
                      <div className="frame-39">
                        <div className="text-wrapper-35">Services we offer  </div>
                        {/* <p className="managing-a-company-s-2">
                          Managing a company&#39;s contingent labor procurement process is one of the tedious
                          operational tasks, and our services can assist you in managing operations effortlessly.
                        </p> */}
                      </div>
                      <div className="frame-40">
                        
                          <div className="frame-42">
                            <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                            <div className="frame-43">
                              <div className="text-wrapper-36">Workforce  </div>
                            </div>
                            
                          </div>
                          <div  className="text-wrapper-36 text-normal" > Cover all aspects of talent attraction, nurture and retention.</div>


                          <div className="frame-42">
                            <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                            <div className="frame-43">
                              <div className="text-wrapper-36">Workplace    </div>
                            </div>
                            
                          </div>
                          <div  className="text-wrapper-36 text-normal" >Cover all aspects of talent attraction, nurture and retention.   </div>


                          <div className="frame-42">
                            <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                            <div className="frame-43">
                              <div className="text-wrapper-36">Workload   </div>
                            </div>
                            
                          </div>
                          <div  className="text-wrapper-36 text-normal" > Provide functional/tech advisory, tech implementation to innovation.   </div>


                          <div className="frame-39">
                        <div className="text-wrapper-35">Services we offer  </div>
                        {/* <p className="managing-a-company-s-2">
                          Managing a company&#39;s contingent labor procurement process is one of the tedious
                          operational tasks, and our services can assist you in managing operations effortlessly.
                        </p> */}
                      </div>

                          <div className="frame-42">
                            <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                            <div className="frame-43">
                              <div className="text-wrapper-36">Discover   </div>
                            </div>
                            
                          </div>
                          <div  className="text-wrapper-36 text-normal" > Understand the business drivers and build the business case   </div>

                          <div className="frame-42">
                            <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                            <div className="frame-43">
                              <div className="text-wrapper-36">Design   </div>
                            </div>
                            
                          </div>
                          <div  className="text-wrapper-36 text-normal" >Design and customize blueprint for GCC/ODC setup   </div>

                          
                          <div className="frame-42">
                            <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                            <div className="frame-43">
                              <div className="text-wrapper-36">Execute    </div>
                            </div>
                            
                          </div>
                          <div  className="text-wrapper-36 text-normal" >Initiate GCC/ODC operations, project execution   </div>

                          <div className="frame-42">
                            <img className="vector" alt="Vector" src="/msp/vector-10.svg" />
                            <div className="frame-43">
                              <div className="text-wrapper-36">Scale     </div>
                            </div>
                            
                          </div>
                          <div  className="text-wrapper-36 text-normal" > Increase GCC/ODC size by scaling out operations   </div>

                        
                        
                       
                      </div>
                    </div>
                  </div>
                  {/* <div className="overlap-10">
                    <img className="rectangle-12" alt="Rectangle" src="/msp/rectangle-138.svg" />
                    <div className="group-9">
                      <div className="frame-46">
                        <div className="text-wrapper-18">Project Team</div>
                      </div>
                      <div className="frame-47">
                        <div className="frame-48">
                          <img className="vector" alt="Vector" src="/msp/vector-1.svg" />
                          <div className="frame-19">
                            <div className="text-wrapper-19">Demand management</div>
                          </div>
                        </div>
                        <div className="frame-18">
                          <img className="vector" alt="Vector" src="/msp/vector-1.svg" />
                          <div className="text-wrapper-20">Stakeholder management</div>
                        </div>
                        <div className="frame-49">
                          <img className="vector" alt="Vector" src="/msp/vector-1.svg" />
                          <div className="text-wrapper-21">Systems management</div>
                        </div>
                        <div className="frame-49">
                          <img className="vector" alt="Vector" src="/msp/vector-1.svg" />
                          <div className="text-wrapper-21">Profiles sanity &amp; checks</div>
                        </div>
                        <div className="frame-49">
                          <img className="vector" alt="Vector" src="/msp/vector-1.svg" />
                          <div className="text-wrapper-21">Interview coordination</div>
                        </div>
                        <div className="frame-49">
                          <img className="vector" alt="Vector" src="/msp/vector-1.svg" />
                          <div className="text-wrapper-21">Continuous Curation of Database</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="text-wrapper-38">Our Structure</div> */}
                  {/* <div className="group-10">
                    <div className="overlap-11">
                      <img className="group-11" alt="Group" src="/msp/group-34.png" />
                      <div className="rectangle-13" />
                      <div className="rectangle-14" />
                      <div className="rectangle-15" />
                      <div className="text-wrapper-39">Requisition PMO</div>
                      <div className="text-wrapper-40">Operation PMO</div>
                      <div className="text-wrapper-41">Vender Management</div>
                    </div>
                    <div className="group-12">
                      <div className="group-13">
                        <div className="overlap-12">
                          <div className="text-wrapper-42">Management</div>
                        </div>
                        <div className="overlap-13">
                          <div className="overlap-group-4">
                            <div className="rectangle-16" />
                            <img className="line-2" alt="Line" src="/msp/line-1.svg" />
                            <img className="ellipse-6" alt="Ellipse" src="/msp/ellipse-10.png" />
                            <div className="text-wrapper-43">Transition manager</div>
                            <img className="ellipse-7" alt="Ellipse" src="/msp/ellipse-8.png" />
                            <img className="group-14" alt="Group" src="/msp/group-31.png" />
                          </div>
                          <img className="group-15" alt="Group" src="/msp/group-32.png" />
                        </div>
                        <div className="overlap-14">
                          <div className="text-wrapper-44">Transition team</div>
                        </div>
                        <div className="overlap-15">
                          <div className="text-wrapper-45">PMO</div>
                        </div>
                        <div className="overlap-16">
                          <div className="rectangle-17" />
                          <div className="text-wrapper-46">Program Manager</div>
                        </div>
                        <img className="group-16" alt="Group" src="/msp/group-33.png" />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="overlap-group-wrapper">
                    <div className="overlap-17">
                      <div className="rectangle-18" />
                      <div className="text-wrapper-31">Transition Team</div>
                      <div className="frame-25">
                        <img className="vector" alt="Vector" src="/msp/vector-16.svg" />
                        <div className="frame-19">
                          <div className="text-wrapper-19">Know Your Client</div>
                        </div>
                      </div>
                      <div className="frame-26">
                        <img className="vector" alt="Vector" src="/msp/vector-16.svg" />
                        <div className="text-wrapper-20">Tools &amp; Techniques</div>
                      </div>
                      <div className="frame-27">
                        <img className="vector" alt="Vector" src="/msp/vector-16.svg" />
                        <div className="text-wrapper-21">Processes &amp; Methodologies</div>
                      </div>
                      <div className="frame-28">
                        <img className="vector" alt="Vector" src="/msp/vector-16.svg" />
                        <div className="text-wrapper-21">Reports &amp; Dashboards</div>
                      </div>
                      <div className="frame-29">
                        <img className="vector" alt="Vector" src="/msp/vector-16.svg" />
                        <div className="text-wrapper-21">Stabilization measures</div>
                      </div>
                      <div className="frame-30">
                        <img className="vector" alt="Vector" src="/msp/vector-16.svg" />
                        <div className="text-wrapper-21">Tech Integrations (if applicable)</div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-2 mobile-only">
            <div className="frame-50">
              <div className="overlap-group-5">
                <img className="rectangle-19" alt="Rectangle" src="/msp/rectangle-26-3.webp" />
                <div className="frame-51">
                  <div className="frame-8" />
                </div>
                <img className="shutterstock" alt="Shutterstock" src="/msp/shutterstock-1704596071-1.webp" />
                <div className="rectangle-20" />
                <div className="frame-52">
                  <p className="we-are-your-growth">
                  Learn About Design Businesses may develop and manage captive insurance solutions with ease by using the 
                  all-inclusive strategy provided by Execute Scale in Captive as a Service. Our service offers end-to-end support, 
                  from the first stages of exploration and design to implementation and scaling. At every step of implementation and expansion, we maximise 
                  cost-saving potential and ensure regulatory compliance by customising captive insurance structures to meet the demands of particular business needs. 
                  </p>
                  <Cta
                    arrowForward="/msp/arrow-forward-91.svg"
                    arrowForwardClassName="CTA-7"
                    className="CTA-5"
                    divClassName="CTA-6"
                    property1="default"
                    text="About us"
                    link="/about-us"
                  />
                </div>
              </div>
            </div>
            <div className="frame-53">
              <img className="rectangle-21" alt="Rectangle" />
              <div className="overlap-18">
                <div className="frame-54">
                  <div className="text-wrapper-47">300+ Internal Staff</div>
                  <div className="frame-55">
                    <p className="our-client-and">
                      {" "}
                      Our client and solution-driven team structure assure maximum leverage of resources for your
                      business. We are your industry-leading growth partners and are trusted by high-end companies for
                      our commitment and timely delivery with efficient and commendable talent fits.
                    </p>
                    <button className="CTA-8">
                      <div className="text-wrapper-48">Learn More</div>
                      <img className="arrow-forward-2" alt="Arrow forward" src="/msp/arrow-forward-92.png" />
                    </button>
                  </div>
                </div>
                <div className="text-wrapper-49">Why work with us?</div>
                <div className="frame-56">
                  <div className="ellipse-8" />
                  <div className="ellipse-9" />
                  <div className="ellipse-8" />
                </div>
              </div>
            </div>
            {/* <Carousel
              arrowForward="/msp/arrow-forward-92.png"
              arrowForwardClassName="carousel-instance"
              className="carousel-03"
            /> */}
          </div>

          <div
            className="overlap-19"
            style={{
              height: screenWidth >= 450 ? "669px" : screenWidth < 450 ? "1050px" : undefined,
              left: screenWidth >= 450 ? "-12px" : screenWidth < 450 ? "-185px" : undefined,
              top: screenWidth >= 450 ? "2100px" : screenWidth < 450 ? "3045px" : undefined,
              width: screenWidth >= 450 ? "1452px" : screenWidth < 450 ? "742px" : undefined,
            }}
          >
            <div
              className="frame-57"
              style={{
                height: screenWidth < 450 ? "307px" : screenWidth >= 450 ? "380px" : undefined,
                left: screenWidth < 450 ? "0" : screenWidth >= 450 ? "3px" : undefined,
                width: screenWidth < 450 ? "742px" : screenWidth >= 450 ? "1449px" : undefined,
              }}
            >
              <div
                className="overlap-group-6"
                style={{
                  height: screenWidth < 450 ? "307px" : screenWidth >= 450 ? "380px" : undefined,
                }}
              >
                <div className="frame-58 desktop-only">
                  <div className="overlap-group-7">
                    <div className="frame-59">
                      <div className="frame-60">
                        <div className="text-wrapper-50">Ready to get started?</div>
                        <p className="are-we-a-good-fit">
                          Are we a good fit for your company&#39;s challenges? Let’s talk it out today
                        </p>
                      </div>
                      <button className="CTA-9">
                        <div className="text-wrapper-51">Learn More</div>
                        <img className="arrow-forward-3" alt="Arrow forward" src="/msp/arrow-forward-97.svg" />
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="frame-61"
                  style={{
                    backgroundColor:
                      screenWidth >= 450 ? "var(--secondary-03)" : screenWidth < 450 ? "#f6f7f8" : undefined,
                    height: screenWidth >= 450 ? "380px" : screenWidth < 450 ? "307px" : undefined,
                    left: screenWidth >= 450 ? "0" : screenWidth < 450 ? "185px" : undefined,
                    width: screenWidth >= 450 ? "724px" : screenWidth < 450 ? "390px" : undefined,
                  }}
                >
                  <div className="desktop-only">
                    <div className="overlap-20">
                      <div className="frame-62">
                        <div className="frame-60">
                          <div className="text-wrapper-52">Ready to get started?</div>
                          <p className="text-wrapper-53">
                            Whether you still have some questions or you’re ready to sign up for a specific services
                            right now, Our specialists are only a message away.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="frame-63">
                      <div className="frame-55">
                        <p className="text-wrapper-54">Set up a virtual briefing</p>
                        <p className="text-wrapper-55">
                          Spend time with our executives and experts virtually and discuss current and future business
                          requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="CTA-10 desktop-only" onClick={() => setOpenModal(true)}>
                  <div className="text-wrapper-56">Schedule a call now</div>
                  <img className="arrow-forward-3" alt="Arrow forward" src="/msp/arrow-forward-98.svg" />
                </button>
                <div className="mobile-only">
                  <div className="frame-64">
                    <div className="frame-65">
                      <div className="frame-66">
                        <div className="frame-67">
                          <div className="frame-68">
                            <p className="text-wrapper-57">Trusted by Fortune 500 Clients</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-69">
                      <img
                        className="mercedes-benz-logo"
                        alt="Mercedes benz logo"
                        src="/logos/mercedes-benz-logo-2010-1.png"
                      />
                      <img
                        className="GE-healthcare-logo"
                        alt="Ge healthcare logo"
                        src="/logos/ge-healthcare-logo-1.png"
                      />
                      <img className="sony-logo" alt="Sony logo" src="/logos/sony-logo-1.png" />
                      <img className="shell-logo" alt="Shell logo" src="/logos/shell-logo-1971-1995-1.png" />
                      <img className="amazon" alt="Amazon" src="/logos/amazon-1-1.png" />
                      <img
                        className="wipro-logo-new-og"
                        alt="Wipro logo new og"
                        src="/logos/wipro-logo-new-og-502x263-1.png"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="mobile-only">
              <div className="frame-73 ">
                <div className="frame-74">
                  <div className="frame-75">
                    <div className="text-wrapper-65">Ready to get started?</div>
                    <p className="text-wrapper-66">
                      Whether you still have some questions or you’re ready to sign up for a specific services right now,
                      Our specialists are only a message away.
                    </p>
                  </div>
                </div>
                <div className="group-19">
                  <div className="frame-76">
                    <div className="overlap-group-8">
                      <div className="frame-77">
                        <div className="text-wrapper-67">Ready to get started?</div>
                        <p className="are-we-a-good-fit-2">
                          Are we a good fit for your company&#39;s challenges? Let’s talk it out today
                        </p>
                        <button className="CTA-11" onClick={() => setOpenModal(true)}>
                          <div className="text-wrapper-56">Schedule a call now</div>
                          <img className="arrow-forward-3" alt="Arrow forward" src="/msp/arrow-forward-94.svg" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="mobile-only">
            <div className="frame-78">
              <Carousel
                arrows={false}
                swipeable
                draggable
                showDots={false}
                responsive={
                  {
                    desktop: {
                      breakpoint: { max: 450, min: 0 },
                      items: 1,
                      slidesToSlide: 1,
                    }
                  }}
                autoPlay={true}
                infinite
                autoPlaySpeed={3200}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="carousel"
                itemClass="carousel-item"
                pauseOnHover={false}
              >
                <div className="frame-79">
                  <img className="deepmind" alt="Deepmind" src="/msp/deepmind-8heReYC6Zt0-unsplash.webp" />
                  <div className="frame-80">
                    <div className="frame-81">
                      <div className="text-wrapper-68">Collaborative Partnership</div>
                      <p className="automate-the-entire">
                      Building cooperative alliances, we collaborate closely with clients, 
                    relying on our mutual trust and common experience to provide strategic advice and faultless execution that guarantees success at every turn. 
                    By means of cooperative alliances, we effectively merge our proficiency with customer insights, 
                    cultivating a dynamic dialogue that amplifies strategy counselling and implementation.
                      </p>
                    </div>
                    {/* <div className="frame-82">
                      <div className="text-wrapper-69">Applicant Friendly</div>
                    </div>
                    <div className="frame-83">
                      <div className="text-wrapper-69">Unprecedented automation</div>
                    </div>
                    <div className="frame-82">
                      <div className="text-wrapper-69">Data Integration</div>
                    </div> */}
                    <div className="frame-8" />
                  </div>
                </div>
                <div className="frame-84">
                  <img className="deepmind" alt="Deepmind" src="/msp/deepmind-8heReYC6Zt0-unsplash-1.webp" />
                  <div className="frame-80">
                    <div className="frame-81">
                      <div className="text-wrapper-68">Strategic Insight</div>
                      <p className="automate-the-entire">
                      Our team provides clients with practical solutions to handle complicated 
                    issues and take advantage of opportunities, assuring optimal outcomes at every turn. 
                    We do this by offering sharp analysis and insight. Because of our experts' in-depth knowledge of market dynamics and industry trends, 
                    we are able to create customised plans that give our clients a competitive edge and long-term growth.
                      </p>
                    </div>
                    {/* <div className="frame-82">
                      <div className="text-wrapper-69">A Comprehensive and effective Database</div>
                    </div>
                    <div className="frame-83">
                      <div className="text-wrapper-69">Business Intelligence</div>
                    </div>
                    <div className="frame-82">
                      <div className="text-wrapper-69">Evaluation</div>
                    </div> */}
                    <div className="frame-8" />
                  </div>
                </div>
                <div className="frame-85">
                  <img className="deepmind" alt="Deepmind" src="/msp/deepmind-8heReYC6Zt0-unsplash-2.webp" />
                  <div className="frame-80">
                    <div className="frame-81">
                      <div className="text-wrapper-68">Continuous Improvement</div>
                      <p className="automate-the-entire">
                      Our dedication to continuous improvement guarantees that our execution and consulting processes change in response to your business requirements, 
                    producing ever-more-effective plans and outcomes. Our commitment to continuous development ensures that our consulting and execution capabilities stay at 
                    the forefront of innovation.
                      </p>
                    </div>
                    {/* <div className="frame-82">
                      <div className="text-wrapper-69">Immediate Email triggers</div>
                    </div>
                    <div className="frame-83">
                      <div className="text-wrapper-69">Real-time reports on compliance</div>
                    </div>
                    <div className="frame-82">
                      <div className="text-wrapper-69">Automated communication</div>
                    </div> */}
                    <div className="frame-8" />
                  </div>
                </div>
              </Carousel>

            </div>
          </div>


          <div className="desktop-only">
            <div className="overlap-22">
              <div className="overlap-23">
                <div className="group-20">
                  <div className="overlap-24">
                    <div className="carousel-3">
                      <div className="frame-86">
                        <div className="overlap-group-9">
                          <img className="rectangle-22" alt="Rectangle" src="/msp/rectangle-26-3.webp" />
                          <img
                            className="shutterstock-2"
                            alt="Shutterstock"
                            src="/msp/shutterstock-1704596071-1-1.webp"
                          />
                          <div className="rectangle-23" />
                          <Cta
                            arrowForward="/msp/arrow-forward-99.svg"
                            className="CTA-12"
                            divClassName="CTA-13"
                            property1="default"
                            text="About us"
                            link="/about-us"
                          />
                        </div>
                      </div>
                      <div className="frame-53">
                        <img className="rectangle-24" alt="Rectangle" />
                        <div className="overlap-18">
                          <div className="frame-54">
                            <div className="text-wrapper-47">300+ Internal Staff</div>
                            <div className="frame-55">
                              <p className="our-client-and">
                                {" "}
                                Our client and solution-driven team structure assure maximum leverage of resources for
                                your business. We are your industry-leading growth partners and are trusted by high-end
                                companies for our commitment and timely delivery with efficient and commendable talent
                                fits.
                              </p>
                              <button className="CTA-8">
                                <div className="text-wrapper-48">Learn More</div>
                                <img className="arrow-forward-4" alt="Arrow forward" src="/msp/arrow-forward-100.png" />
                              </button>
                            </div>
                          </div>
                          <div className="text-wrapper-49">Why work with us?</div>
                          <div className="frame-56">
                            <div className="ellipse-8" />
                            <div className="ellipse-9" />
                            <div className="ellipse-8" />
                          </div>
                        </div>
                      </div>
                      {/* <Carousel
                        arrowForward="/msp/arrow-forward-100.png"
                        arrowForwardClassName="carousel-03-instance"
                        className="carousel-03"
                      /> */}
                    </div>
                    <p className="we-are-your-growth-2">
                      We are your growth partners for a wide array of holistic talent solutions.&nbsp;&nbsp;Leverage our
                      team of industry experts, management practitioners, and training partners catered to your growth.
                    </p>
                  </div>
                </div>
                
              </div>
              
             
            </div>
            <div className="overlap-25">
              <div className="frame-93">
                <div className="frame-65">
                  <div className="frame-66">
                    <div className="frame-67">
                      <div className="frame-68">
                        <p className="text-wrapper-57">Trusted by Fortune 500 Clients</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-69">
                  <img
                    className="mercedes-benz-logo-2"
                    alt="Mercedes benz logo"
                    src="/logos/mercedes-benz-logo-2010-1.png"
                  />
                  <img
                    className="GE-healthcare-logo-2"
                    alt="Ge healthcare logo"
                    src="/logos/ge-healthcare-logo-1.png"
                  />
                  <img className="sony-logo-2" alt="Sony logo" src="/logos/sony-logo-1.png" />
                  <img className="shell-logo-2" alt="Shell logo" src="/logos/shell-logo-1971-1995-1.png" />
                  <img className="amazon-2" alt="Amazon" src="/logos/amazon-1-1.png" />
                  <img
                    className="wipro-logo-new-og-2"
                    alt="Wipro logo new og"
                    src="/logos/wipro-logo-new-og-502x263-1.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>

  );
};
