import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

const items = [
  {
    name: "Workforce Solutions",
    link: "/workforce-solutions",
    submenu1: [
      { name: "Staffing", link: "/staffing-solution" },
      { name: "MSP", link: "/msp" },
      { name: "RPO", link: "/rpo" },
    ],
  },
  {
    name: "Advisory",
    link: "/advisory",
  },
  {
    name: "Technology Solutions",
    link: "/it-services",
  },
  {
    name: "Plaformised Services",
    link: "/consulting",
    submenu: [
      { name: "J2W Offer Letter", link: "/offer-letter-platform" },
      { name: "J2W Premiere Lounge", link: "/j2w-premiere-lounge" },
    ],
  },
  {
    name: "Captive as a Service",
    link: "/captive",
  },
];

export const SolutionDropdown = (props) => {
  const ref = useRef(null);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSubMenu1, setShowSubMenu1] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        props.setOpenSolutions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, props]);

  const handleMouseEnter = (item) => {
    if (item.name === "Plaformised Services") {
      setShowSubMenu(true);
    } else {
      setShowSubMenu(false);
    }

    if (item.name === "Workforce Solutions") {
      setShowSubMenu1(true);
    } else {
      setShowSubMenu1(false);
    }
  };

  const handleMouseLeave = () => {
    setShowSubMenu(false);
  };

  return (
    <div className="solutiondropdown" id="dropdown1" ref={ref}>
      <div className="menu-section">
        {items.map((item, index) => (
          <div
            key={index}
            onMouseEnter={() => handleMouseEnter(item)}
            onMouseLeave={handleMouseLeave}
          >
            <Link to={item.link}>
              <div className="nav-item">
                <p>{item.name}</p>
              </div>
            </Link>
            {showSubMenu && item.submenu && (
              <div className="menu-section">
                {item.submenu.map((subItem, subIndex) => (
                  <Link to={subItem.link} key={subIndex}>
                    <div className="sub_menu">
                      <p>{subItem.name}</p>
                    </div>
                  </Link>
                ))}
              </div>
            )}

            {showSubMenu1 && item.submenu1 && (
              <div className="menu-section">
                {item.submenu1.map((subItem, subIndex) => (
                  <Link to={subItem.link} key={subIndex}>
                    <div className="sub_menu">
                      <p>{subItem.name}</p>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
