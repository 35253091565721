import React, { useEffect, useState } from "react";
import { StateDefaultWrapper } from "../../msp/StateDefaultWrapper";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";


const items = [
  {
    name: "Workforce Solutions",
    link: "/workforce-solutions",
    submenu1: [
      { name: "MSP", link: "/msp" },
      { name: "RPO", link: "/rpo" },
    ],
  },
  {
    name: "Advisory",
    link: "/Advisory",
  },
  {
    name: "Technology Solutions",
    link: "/it-services",
  },
  {
    name: "Plaformised Services",
    link: "/consulting",
    submenu: [
      { name: "J2W Offer Letter", link: "/offer-letter-platform" },
      { name: "J2W Premiere Lounge", link: "/j2w-premiere-lounge" },
    ],
  },
  {
    name: "Captive as a Service",
    link: "/captive",
  },
];


export const MobileNav = ({ show, setShow }) => {
  const [solutions, setSolutions] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSubMenu1, setShowSubMenu1] = useState(false);
  const [platforms, setPlatforms] = useState(false);
  const navigate = useNavigate();

  const handleSolutions = (item) => {
    console.log(item.name)
    if (item.name === "Plaformised Services" || item.name === "Workforce Solutions") {  
      
      if(item.name === "Plaformised Services"){
      setShowSubMenu(!showSubMenu);
      }
      else if(item.name === "Workforce Solutions"){
        setShowSubMenu1(!showSubMenu1);
      }
      else{
        setSolutions(!solutions);
        navigate(item.link);
      }
    } else {
      setSolutions(!solutions);
      navigate(item.link);
    }


  };


  return (
    <div className="mobile-nav mobile-only">
      <div className="div-4-drop">
        <div className="frame-94-drop">
          <img className="logo-4-drop" alt="Logo" src="/services/logo-1.png" />
          <img
            className="close-drop"
            alt="Close"
            src="/services/close-1.png"
            onClick={() => setShow(false)}
          />
        </div>
        <div className="holder-wrapper-drop">
          <div className="holder-drop">
            <div
              className="frame-95-dropdown"
            
            >
              <button
                className="text-wrapper-75-drop"
                style={{ alignSelf: "center" }}  onClick={() => setSolutions(!solutions)}
              >
                Services
              </button>
              {solutions && (
                <div className="dropdown-wrapper">
                  {items.map((item) => (
                  <div key={item.name} onClick={() => {handleSolutions(item)}}>
                        <div className="dropdown-text-wrapper ">{item.name}</div>
                        {showSubMenu && item.submenu && (
                        <div className="dropdown-wrapper submenu">
                          {item.submenu.map((subItem, subIndex) => (
                            <Link to={subItem.link} key={subIndex}>
                              <div  className="dropdown-text-wrapper">
                                <p>{subItem.name}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      )}

{showSubMenu1 && item.submenu1 && (
                        <div className="dropdown-wrapper submenu">
                          {item.submenu1.map((subItem, subIndex) => (
                            <Link to={subItem.link} key={subIndex}>
                              <div  className="dropdown-text-wrapper">
                                <p>{subItem.name}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* <div className="frame-95-dropdown">
              <button
                className="text-wrapper-75-drop"
                onClick={() => setPlatforms(!platforms)}
                style={{ alignSelf: "center" }}
              >
                Platforms
              </button>
              {platforms && (
                <div className="dropdown-wrapper">
                  <Link to="/offer-letter-platform">
                    <div className="dropdown-text-wrapper">
                      J2W Offer Letter
                    </div>
                  </Link>
                  <Link to="/j2w-premiere-lounge">
                    <div className="dropdown-text-wrapper">
                      J2W Premiere Lounge
                    </div>
                  </Link>
                </div>
              )}
            </div> */}
            <Link to="/about-us">
              <div className="frame-95-drop">
                <div className="text-wrapper-75-drop"> About Us</div>
              </div>
            </Link>
            <Link to="/leadership">
              <div className="frame-95-drop">
                <div className="text-wrapper-75-drop">Leadership</div>
              </div>
            </Link>
            <Link to="/industries">
              <div className="frame-95-drop">
                <div className="text-wrapper-75-drop">Industries</div>
              </div>
            </Link>
            <Link to="/careers">
              <div className="frame-95-drop">
                <div className="text-wrapper-75-drop">Careers</div>
              </div>
            </Link>
            <StateDefaultWrapper
              className="design-component-instance-node"
              default1
              divClassName="CTA-14-drop"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
